import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between" }
const _hoisted_2 = { class: "p-input-icon-left p-col-4" }
const _hoisted_3 = { class: "table-filters-panel" }

import dateFormat from '@/helpers/DateFormat.helper';
import { ref, PropType, computed } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { PatientSupplementTableDto, SupplementType } from '@/models/Supplement';
import { useI18n } from 'vue-i18n';
import HealzButton from '@/components/healz/ui/HealzButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientSupplementsTable',
  props: {
  supplements: {
    type: Array as PropType<PatientSupplementTableDto[]>,
    required: true,
  },
  initFilterBy: {
    type: String,
    required: false,
  },
},
  emits: ['supplement-detail-click'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();

const props = __props;

const emits = __emit;

const filterBy = ref(props.initFilterBy ?? 'all');
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  types: { value: [], matchMode: FilterMatchMode.CONTAINS },
});

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    types: { value: [], matchMode: FilterMatchMode.CONTAINS },
  };

  filterBy.value = 'all';
};

initFilters();

const loading = computed(() => !props.supplements);
const showPaginator = computed(() => props.supplements && props.supplements.length > 10);

const supplementsFiltered = computed(() => {
  if (!props.supplements) {
    return [];
  }

  switch (filterBy.value) {
    case 'all':
      return props.supplements;
    case 'finished':
      return props.supplements.filter((supplement) => new Date() > new Date(supplement.endDate));
    case 'in-use':
      return props.supplements.filter(
        (supplement) => new Date(supplement.startDate) <= new Date() && new Date(supplement.endDate) >= new Date(),
      );
    default:
      return props.supplements;
  }
});

const onSupplementShopClick = (supplement: PatientSupplementTableDto) => {
  window.open(supplement.shoppingUrl, '_blank');
};

const supplementOptions = computed(() => {
  return Object.keys(SupplementType)
    .map((key) => ({
      label: t(`supplementation.type.${key}`),
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
});

const onSupplementDetailClick = (supplement: PatientSupplementTableDto) => {
  emits('supplement-detail-click', supplement);
};

const rowClickEvent = (event: any) => {
  onSupplementDetailClick(event.data);
};

const changeFilter = (newFilter: string) => {
  filterBy.value = newFilter;
};

const renderTypes = (types: string[]) => {
  if (!types) {
    return '';
  }

  const translatedTypes = types
    .filter((type) => Object.keys(SupplementType).includes(type))
    .map((type) => t(`supplementation.type.${type}`));
  return translatedTypes.join(', ');
};

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (supplementsFiltered.value)
    ? (_openBlock(), _createBlock(_component_DataTable, {
        key: 0,
        ref: "supplementsPatientTable",
        filters: filters.value,
        "onUpdate:filters": _cache[4] || (_cache[4] = ($event: any) => ((filters).value = $event)),
        "filter-display": "menu",
        value: supplementsFiltered.value,
        "data-key": "id",
        rows: 10,
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
        paginator: showPaginator.value,
        "responsive-layout": "scroll",
        loading: loading.value,
        "global-filter-fields": ['name', 'supplement.internalName'],
        "sort-field": "name",
        "sort-order": 1,
        "removable-sort": "",
        rowHover: true,
        onRowClick: rowClickEvent
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, [
              _cache[5] || (_cache[5] = _createElementVNode("i", {
                class: "icon-search",
                "aria-hidden": "true"
              }, null, -1)),
              _createVNode(_component_InputText, {
                modelValue: filters.value['global'].value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value['global'].value) = $event)),
                placeholder: _ctx.$t('table.search.events')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _createVNode(_component_Button, {
              type: "button",
              class: "p-button p-component p-button-secondary p-button-medium p-button-icon",
              onClick: initFilters
            }, {
              default: _withCtx(() => [
                _cache[6] || (_cache[6] = _createElementVNode("i", {
                  class: "icon-trash_empty",
                  "aria-hidden": "true"
                }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('table.clearFilters')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["filter-button", { active: filterBy.value === 'all' }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (changeFilter('all')))
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('common.all')), 1)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["filter-button", { active: filterBy.value === 'in-use' }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (changeFilter('in-use')))
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('common.in-use')), 1)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["filter-button", { active: filterBy.value === 'finished' }]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (changeFilter('finished')))
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('common.finished')), 1)
            ], 2)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "name",
            header: _ctx.$t('supplementation.patient.tableColumns.name'),
            sortable: ""
          }, {
            body: _withCtx((slotProps) => [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "icon__container p-mr-2" }, [
                _createElementVNode("i", {
                  class: "icon-capsules",
                  "aria-hidden": "true"
                })
              ], -1)),
              _createTextVNode(" " + _toDisplayString(slotProps.data?.name), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "supplement.internalName",
            header: _ctx.$t('supplementation.patient.tableColumns.internalName'),
            sortable: ""
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.supplement?.internalName), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "types",
            "filter-field": "types",
            header: _ctx.$t('supplementation.patient.tableColumns.type'),
            showClearButton: false,
            showApplyButton: false,
            showFilterMatchModes: false
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(renderTypes(data.types)), 1)
            ]),
            filter: _withCtx(({ filterModel, filterCallback }) => [
              _createVNode(_component_MultiSelect, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                options: supplementOptions.value,
                "option-label": "label",
                "option-value": "value",
                placeholder: _ctx.$t('supplementation.tableColumns.typeFilter'),
                class: "p-column-filter",
                onChange: ($event: any) => (filterCallback())
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder", "onChange"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "professional",
            header: _ctx.$t('supplementation.patient.tableColumns.professional')
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data?.professional), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "startDate",
            header: _ctx.$t('supplementation.patient.tableColumns.startDate'),
            "data-type": "date",
            "show-filter-match-modes": false,
            "show-apply-button": false
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(_unref(dateFormat).formatDate(_unref(dateFormat).formatLocalTimezone(slotProps.data.startDate))), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "endDate",
            header: _ctx.$t('supplementation.patient.tableColumns.endDate'),
            "data-type": "date",
            "show-filter-match-modes": false,
            "show-apply-button": false
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(_unref(dateFormat).formatDate(_unref(dateFormat).formatLocalTimezone(slotProps.data.endDate))), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('table.actions')
          }, {
            body: _withCtx((slotProps) => [
              _createVNode(HealzButton, {
                disabled: false,
                class: "p-button-table",
                onClick: ($event: any) => (onSupplementDetailClick(slotProps.data))
              }, {
                content: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createElementVNode("i", {
                    class: "icon-show",
                    "aria-hidden": "true"
                  }, null, -1)
                ])),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(HealzButton, {
                disabled: slotProps.data.shoppingUrl === null || slotProps.data.shoppingUrl === '',
                class: "p-button-table",
                onClick: ($event: any) => (onSupplementShopClick(slotProps.data))
              }, {
                content: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createElementVNode("i", {
                    class: "icon-shop",
                    "aria-hidden": "true"
                  }, null, -1)
                ])),
                _: 2
              }, 1032, ["disabled", "onClick"])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["filters", "value", "paginator", "loading"]))
    : _createCommentVNode("", true)
}
}

})