import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = {
  key: 0,
  class: "p-grid"
}
const _hoisted_3 = { class: "p-col-12" }
const _hoisted_4 = {
  key: 0,
  class: "c-supplements-container"
}
const _hoisted_5 = {
  key: 1,
  class: "c-supplements-empty-state-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_PatientSupplementsTable = _resolveComponent("PatientSupplementsTable")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_PatientAddSupplementModal = _resolveComponent("PatientAddSupplementModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : ''),
      "heading-icon": "icon-supplements",
      description: _ctx.$t('supplementation.patient.subtitle')
    }, {
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button p-button-icon",
          onClick: _ctx.onAddSupplementClick
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("i", {
              class: "icon-plus",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('supplementation.patient.new')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["title", "description"]),
    _createElementVNode("main", _hoisted_1, [
      _withDirectives(_createVNode(_component_LoadingComponent, { "spinner-color": "lavender-700" }, null, 512), [
        [_vShow, _ctx.loadingSupplements]
      ]),
      (!_ctx.loadingSupplements)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.patientSupplementTableDtos && _ctx.patientSupplementTableDtos.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_PatientSupplementsTable, {
                      initFilterBy: _ctx.filter,
                      supplements: _ctx.patientSupplementTableDtos,
                      onSupplementDetailClick: _ctx.onSupplementDetailClick
                    }, null, 8, ["initFilterBy", "supplements", "onSupplementDetailClick"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_EmptyState, {
                      heading: _ctx.$t('supplementation.patient.isEmtyTitle'),
                      text: _ctx.$t('supplementation.patient.isEmptyMessage'),
                      secondary: false,
                      icon: "icon-supplements"
                    }, {
                      bottom: _withCtx(() => [
                        _createVNode(_component_Button, {
                          class: "p-button p-button-icon",
                          onClick: _ctx.onAddSupplementClick
                        }, {
                          default: _withCtx(() => [
                            _cache[1] || (_cache[1] = _createElementVNode("i", {
                              class: "icon-plus",
                              "aria-hidden": "true"
                            }, null, -1)),
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('supplementation.patient.new')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }, 8, ["heading", "text"])
                  ]))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_PatientAddSupplementModal, {
      ref: "supplementPatientModalRef",
      patient: _ctx.patient,
      onOnCreateSupplement: _ctx.loadSupplementsList
    }, null, 8, ["patient", "onOnCreateSupplement"])
  ], 64))
}